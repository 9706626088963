import styled from 'styled-components';

interface Props {
  dir: 'left' | 'right';
}

export const ImgLeftRight = styled.div<Props>`
  margin: 1rem 0 10rem 0;

  display: grid;
  gap: 4rem;
  grid-template-columns: ${p => (p.dir === 'left' ? '1fr 2fr' : '2fr 1fr')};
  align-items: center;

  font-size: 1rem;

  h3 {
    font-size: 1.8rem;
  }

  p {
    margin-bottom: 0.8rem;
  }

  .gatsby-image-wrapper {
    max-height: 300px;
    min-height: 200px;
    border-radius: 6px;
    filter: drop-shadow(0 4px 12px rgba(0, 0, 0, 0.2));
  }

  @media (max-width: 800px) {
    margin: 1rem 0 2rem 0;
    gap: 1rem;

    grid-template-columns: 1fr;
    grid-template-rows: 30vh auto;

    .gatsby-image-wrapper {
      max-height: 100%;
      width: 100%;
      justify-self: center;
      grid-row-start: 1;
    }
  }
`;
