import { graphql, Link } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { ImgLeftRight } from '../components/ImgLeftRight';
import SEO from '../components/SEO';
import { SanityImage } from '../lib/types';

export const query = graphql`
  {
    settings: sanitySiteSettings {
      title
      prijave
      heroName
      heroDescription
      heroImage {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [WEBP, AUTO, JPG])
        }
      }
    }
    firstPage: allSanityFirstPage {
      nodes {
        id
        title
        description
        image {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [WEBP, AUTO, JPG])
          }
        }
      }
    }
  }
`;

interface Props {
  data: {
    settings: { title: string; prijave: boolean; heroName: string; heroDescription: string; heroImage: SanityImage };
    firstPage: {
      nodes: { id: string; title: string; description: string; image: SanityImage }[];
    };
  };
}

const index: React.FC<Props> = ({ data }) => {
  return (
    <>
      <SEO />
      <MainContainer>
        <TextContainer>
          <ColorLine w="100%" color="var(--rumena)" />
          <h2>{data.settings.title}</h2>
          <ColorLine w="50%" color="var(--modra)" />
          {data.settings.prijave && (
            <Btn to="https://app.oratorij.net/prijava/oratorij-zelimlje" target="_blank">
              Prijavnica &nbsp; →
            </Btn>
          )}
        </TextContainer>
        <ImageContainer>
          <StaticImage placeholder="tracedSVG" src="../assets/images/circle-img.png" alt="otroci na oratoriju" />
        </ImageContainer>
      </MainContainer>
      <OratorijHeroRow>
        <GatsbyImage image={data.settings.heroImage.asset.gatsbyImageData} alt={data.settings.heroName} />
        <div>
          <h3>{data.settings.heroName}</h3>
          <p>{data.settings.heroDescription}</p>
        </div>
      </OratorijHeroRow>
      {data.firstPage.nodes.map((data, index) => {
        const dir = index % 2 === 1 ? 'left' : 'right';
        return (
          <ImgLeftRight dir={dir} key={data.id}>
            {dir === 'left' && <GatsbyImage image={data.image.asset.gatsbyImageData} alt={data.title} />}
            <div>
              <h3>{data.title}</h3>
              <p>{data.description}</p>
            </div>
            {dir === 'right' && <GatsbyImage image={data.image.asset.gatsbyImageData} alt={data.title} />}
          </ImgLeftRight>
        );
      })}
    </>
  );
};

const MainContainer = styled.div`
  height: calc(100vh - var(--nav-size) - 1rem);
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 2rem;

  @media (max-width: 1000px) {
    padding-bottom: 2rem;
    padding-top: 2rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  @media (max-width: 500px) {
    padding-top: 1rem;
  }
`;

const OratorijHeroRow = styled.div`
  margin: 1rem 0 10rem 0;

  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr 2fr;
  align-items: center;

  font-size: 1rem;

  h3 {
    font-size: 2rem;
  }

  @media (max-width: 800px) {
    margin: 1rem 0 4rem 0;
    gap: 2rem;

    grid-template-columns: 1fr;
    grid-template-rows: 25vh auto;

    .gatsby-image-wrapper {
      width: 40vw;
      justify-self: center;
    }
  }

  @media (max-width: 400px) {
    .gatsby-image-wrapper {
      width: 50vw;
    }
  }
`;

const Btn = styled(Link)`
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  margin-top: 3.4rem;
  padding: 1rem 2.4rem;
  font-size: 1rem;
  background: var(--modra);
  color: #fff;
  border-radius: 2px;

  @media (max-width: 800px) {
    margin-top: 2rem;
    padding: 0.6rem 1.4rem;
    font-size: 0.9rem;
  }
`;

const TextContainer = styled.div`
  max-width: 75%;
  justify-self: start;

  @media (max-width: 800px) {
    align-self: flex-start;
  }

  @media (max-width: 450px) {
    max-width: 100%;
  }

  h2 {
    margin: 1rem 0;
    font-size: 2.8rem;
    @media (max-width: 1200px) {
      font-size: 2rem;
    }
    @media (max-width: 800px) {
      font-size: 1.8rem;
    }
    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
`;

const ColorLine = styled.div<{ w: string; color: string }>`
  height: 0.4rem;
  width: ${p => p.w};
  background-color: ${p => p.color};
  border-radius: 100px;
`;

const ImageContainer = styled.div`
  position: relative;

  height: 80%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    height: 60%;
    width: 60%;
    justify-self: end;
    align-self: center;
    margin: 0 6vw 6vw 0;
  }

  @media (max-width: 500px) {
    height: 75%;
    width: 75%;
    margin: 0 1rem 1rem 0;
  }

  @media (max-width: 400px) and (max-height: 700px) {
    height: 65%;
    width: 65%;
    align-self: end;
  }
`;

export default index;
